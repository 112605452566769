<template>
  <bg-sidebar
    class="sidebar"
    logo-to="/"
  >
    <show-for-allowed-app :app="APPS.PAYMENTS.key">
      <bg-sidebar-group
        key="payments"
        label="Pagamentos"
        :icon="{ name: 'dollar' }"
      >
        <bg-sidebar-item
          :to="{ name: 'BatchesPage' }"
          label="Lotes"
        />
        <bg-sidebar-item
          :to="{ name: 'TransfersPage' }"
          label="Transferências"
        />
        <bg-sidebar-item
          :to="{ name: 'BilletsPage' }"
          label="Boletos"
        />
        <bg-sidebar-item
          :to="{ name: 'ReceiversPage' }"
          label="Recebedores"
        />
        <tr-hide-for-roles :roles="appRoles.ANALYST">
          <bg-sidebar-item
            :to="{ name: 'SheetImportPage' }"
            label="Importação"
          />
        </tr-hide-for-roles>
        <bg-sidebar-item
          :to="{ name: 'IndicatorPage' }"
          label="Indicadores"
        />
        <bg-sidebar-item
          :to="{ name: 'PaymentsSettings' }"
          label="Configurações"
        />
      </bg-sidebar-group>
    </show-for-allowed-app>
    <show-for-allowed-app :app="APPS.PAYMENTS.key">
      <bg-sidebar-group
        key="payins"
        :data-tour="ELEMENT_IDS.PAYIN"
        label="Recebimentos"
        :icon="{ name: 'dollar-doc' }"
      >
        <bg-sidebar-item
          :to="{ name: 'PayinsPage' }"
          label="Seus recebimentos"
        />
        <!-- TODO: Uncomment after new provider integration -->
        <!-- <bg-sidebar-item
          :to="{ name: 'CardReceivablesListPage' }"
          label="Cartão de crédito"
        /> -->
        <bg-sidebar-item
          :to="{ name: 'PaymentLinkListPage' }"
          label="Links de pagamento"
        />
      </bg-sidebar-group>
    </show-for-allowed-app>
    <show-for-allowed-app :app="APPS.CONTACERTA.key">
      <bg-sidebar-group
        key="contacerta"
        label="ContaCerta"
        :icon="{ name: 'user-check' }"
      >
        <tr-hide-for-roles :roles="appRoles.ANALYST">
          <bg-sidebar-item
            :to="{ name: 'ContaCertaNewValidationPage' }"
            label="Validação de dados"
          />
        </tr-hide-for-roles>
        <bg-sidebar-item
          :to="{ name: 'ContaCertaValidationHistoryPage' }"
          label="Histórico"
        />
      </bg-sidebar-group>
    </show-for-allowed-app>
    <show-for-feature-flag flag="med-panel">
      <show-for-allowed-app :app="APPS.PAYMENTS.key">
        <bg-sidebar-group
          key="contestacoes"
          label="Contestações"
          :decoration="contestationsDecoration"
          :icon="{ name: 'doc' }"
        >
          <bg-sidebar-item
            :to="{ name: 'InfractionsPage' }"
            label="Infrações e MED"
          />
        </bg-sidebar-group>
      </show-for-allowed-app>
    </show-for-feature-flag>
  </bg-sidebar>
</template>

<script setup>
import { BgSidebar, BgSidebarGroup, BgSidebarItem } from '@transfeeradev/bridge';
import appRoles from '@transfeeradev/api-enums/roles/appRoles';
import ShowForAllowedApp from '@/commons/components/container/ShowForAllowedApp.vue';
import ShowForFeatureFlag from './commons/components/container/ShowForFeatureFlag.vue';
import { APPS } from '@/commons/constants/apps';
import { useTour } from '@/commons/composables/useTour';
import checkpoint from '@/commons/constants/checkpoint';
import checkpointResource from '@/commons/resources/checkpoint';
import { captureException } from '@sentry/vue';
import { onMounted, ref } from 'vue';

const contestationsDecoration = ref();

const ELEMENT_IDS = {
  CONTESTATIONS: 'contestations-sidebar-group'
};

const setupTourElements = async () => {
  const elementsIds = [];

  const { exists: medPanelExists } = await checkpointResource.exists(checkpoint.INFRACTION_CONTESTATION_PAGE_VIEWED);

  if (!medPanelExists) {
    contestationsDecoration.value = 'new';
    elementsIds.push(ELEMENT_IDS.CONTESTATIONS);
  }

  return elementsIds;
};

const setupTour = async () => {
  const steps = {};

  if (Object.keys(steps).length === 0) {
    return;
  }

  const getStep = el => steps[el.getAttribute('data-tour')];
  const elementsIds = await setupTourElements();

  if (elementsIds?.length) {
    const { defineSteps } = useTour({ elementsIds });

    defineSteps(els => els.map(el => getStep(el).builder(el)), {
      doneLabel: 'Entendi'
    })
      .onChange(el => checkpointResource.create(getStep(el).checkpoint))
      .start();
  }
};

onMounted(() => {
  setupTour().catch(captureException);
});
</script>
